export const environment = {
  production: false,
 
  apiUrl: "https://awscore.diaq.in/api/",
  dap_imageUrl: "https://awsnode.diaq.in/",
  dap_weatherUrl: "https://api.weatherapi.com/v1/",
 
  dap_url: "https://awsnode.diaq.in/",
};

//https://core-ce.diaq.in/
